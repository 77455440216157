const JobPlacementMenu = [
  {
    heading: 'menu',
    route: '/job-placement',
    pages: [
      {
        heading: 'Job Placement DashBoard',
        route: '/job-placement/dashboard',
        fontIcon: 'fa-search',
      },
      {
        heading: 'Job Placement List',
        route: '/job-placement/list',
        fontIcon: 'fa-search',
      },
      // {
      //   heading: "Enrolled Trainee",
      //   route: "/job-placement/enrolled-trainee",
      //   fontIcon: "fa-search",
      // },
      // {
      //   heading: "Certificate Complete Trainee",
      //   route: "/job-placement/certificate-complete-trainee",
      //   fontIcon: "fa-search",
      // },
      // {
      //   heading: "Job Tracking",
      //   route: "/job-placement/job-tracking",
      //   fontIcon: "fa-search",
      // },

      {
        heading: 'Self Employment',
        route: '/job-placement/self-employment',
        fontIcon: 'fa-search',
      },
      {
        heading: 'Wages Employment',
        route: '/job-placement/wages-employee',
        fontIcon: 'fa-search',
      },
      {
        heading: 'Upskill Employment',
        route: '/job-placement/upskill-information',
        fontIcon: 'fa-info',
      },
      {
        heading: 'Job Release',
        route: '/job-placement/job-release',
        fontIcon: 'fa-info',
      },
      {
        heading: 'Job History',
        route: '/job-placement/job-history',
        fontIcon: 'fa-info',
      },
      {
        heading: 'Show Placement History',
        route: '/job-placement/show-placement',
        fontIcon: 'fa-info',
      },
    ],
  },
];

export default JobPlacementMenu;
